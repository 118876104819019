<template>
  <b-modal id="modal-categoriaSeguro"
    title="Categoria Seguro"
    hide-footer
  >
    <div class="row mb-3">
      <div class="col-sm-7 offset-sm-1">
        <input type="text" class="form-control"
          v-model="inputCategoria"
          @keyup.enter="agregarCategoria"
          placeholder="Ingrese una Categoria"
          :disabled="inputDisabled"
          autocomplete="off">
      </div>
      <div class="col-sm-3">
        <button class="btn btn-warning" @click="agregarCategoria">
          Agregar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <b-table responsive
          class="table table-striped table-bordered border-0 table-hover"
          :fields="fieldsTabla"
          :items="listaCategorias">
          <template #cell(accion)="row">
            <div class="pt-2 text-center">
              <i
                class="nav-icon i-Close-Window text-danger font-weight-bold"
                style="cursor: pointer"
                v-if="row.item.total_categoria === 0"
                @click="eliminarCategoria(row.item)"
              ></i>
              <i v-else class="nav-icon i-Close-Window text-danger"
                :disable="true"
                :title="message">
                </i>
            </div>
          </template>
          <template #cell(numero)="row">
             <label
              style="cursor: pointer"
              class="col-form-label">
              {{row.item.numero}}
            </label>
          </template>
          <template #cell(nombre)="row">
            <label @click="row.item.editable = true"
              v-show="!row.item.editable"
              style="cursor: pointer"
              class="col-form-label">
              {{row.item.nombre}}
            </label>
            <input
              v-model="row.item.nombre"
              v-show="row.item.editable"
              :maxlength="maxLengthInput"
              @blur="actualizarCategoria(row.item);row.item.editable = false"
              type="text"
              class="form-control"
            >
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ModalCategoriaSeguro',
  data() {
    return {
      inputCategoria: '',
      inputDisabled: false,
      fieldsTabla: [
        {
          key: 'accion', label: '', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'numero', label: 'Id', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '80%' },
        },
      ],
      listaCategorias: [],
      maxLengthInput: 191,
      message: 'No se puede eliminar, esta categoría esta siendo ocupada por alguna aseguradora',
    };
  },
  created() {
    this.listarCategorias();
  },
  methods: {
    async listarCategorias() {
      try {
        const RESPONSE = await axios.get('/clinic/insurer/categoria');
        const LISTA_TEMPORAL = RESPONSE.data.data;
        LISTA_TEMPORAL.forEach((item, index) => { LISTA_TEMPORAL[index].editable = false; });
        this.listaCategorias = LISTA_TEMPORAL;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async crearCategoria() {
      const REQUEST = { nombre: this.inputCategoria };
      let estado = false;
      try {
        const response = await axios.post('/clinic/insurer/categoria', REQUEST);
        await this.listarCategorias();
        estado = true;
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        const mensajes = error.response.data.data.nombre;
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      return estado;
    },
    agregarCategoria() {
      this.inputDisabled = true;
      if (this.crearCategoria()) {
        this.inputCategoria = '';
      }
      this.inputDisabled = false;
    },
    async actualizarCategoria(item) {
      const REQUEST = { nombre: item.nombre };
      try {
        const RESPONSE = await axios.put(`/clinic/insurer/categoria/${item.id}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async eliminarCategoria(fila) {
      try {
        this.listaCategorias = this.listaCategorias.filter((item) => (
          item.id !== fila.id
        ));
        const RESPONSE = await axios.delete(`/clinic/insurer/categoria/${fila.id}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarCategorias();
      }
    },
  },
};
</script>

<style scoped>
</style>
