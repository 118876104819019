<template>
  <div>
    <div class="breadcrumb">
      <h1 class="mb-0"><strong>Parametros</strong></h1>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-10">
                <button
                  class="btn mr-3"
                  :class="isEstructuraConsultorio? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'EstructuraConsultorio'">
                  Estructura Consultorio
                </button>
                <button
                  class="btn mr-3"
                  :class="isDiagnosticoCie ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'DiagnosticoCie'"
                >
                  Diagnóstico CIE
                </button>
                <button
                  class="btn mr-3"
                  :class="isArancel ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'Arancel'"
                >
                  Arancel
                </button>
                <button
                  class="btn mr-3"
                  :class="isOtros ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'Otros'"
                >
                  Otros
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-9" v-if="isEstructuraConsultorio">
                <div class="row">
                  <button
                    class="btn border border-light col-sm-2 mx-3"
                    :class="isCrear ? 'btn-light text-dark': 'btn-white'"
                    @click="optionSelected = 'Crear'"
                  >
                    <b>Crear</b>
                  </button>
                  <button
                    class="btn border border-light col-sm-2 mr-3"
                    :class="isAsignar ? 'btn-light text-dark': 'btn-white'"
                    @click="optionSelected = 'Asignar'"
                  >
                    <b>Asignar</b>
                  </button>
                </div>
              </div>
              <div v-if="isSalaCama" class="col-sm-1 pl-2 pr-0 pt-2">
                <label for="hora_salida" class="col-form-label text-right">
                  <strong>Hora de Salida:</strong>
                </label>
              </div>
              <div v-if="isSalaCama" class="col-sm-2 pt-2 text-right">
                <div class="row col-sm-10 pl-2">
                  <b-input-group>
                    <input
                      id="hora_salida"
                      v-model="horaSalidaComputed"
                      type="time"
                      class="form-control text-center"
                    >
                  <b-input-group-append>
                    <button
                      class="btn btn-light px-2"
                      @click="saveHoraSalida"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-floppy"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 2H9v3h2z"/>
                        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                          2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                          0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                          0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                          0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                          7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                          .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                          0 0 0-.5.5z"
                        />
                      </svg>
                    </button>
                  </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </div>
            <Crear v-if="isEstructuraConsultorio && isCrear"/>
            <Asignar @sala-cama-emitido="handleSalaCamaEmitido" v-if="isEstructuraConsultorio && isAsignar"/>
            <TheTabClasificacionCie v-if="isDiagnosticoCie"/>
            <TheTabArancel v-if="isArancel"/>
            <TheTablCategoriaLinae v-if="isCategoriaLinae"/>
            <TheOtros v-if="isOtros" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Crear from '../components/TheCrear.vue';
import Asignar from '../components/TheAsignar.vue';
import TheTablCategoriaLinae from '../components/TheTabCategoriaLinae.vue';
import TheTabClasificacionCie from '../components/TheTabClasificacionCie.vue';
import TheTabArancel from '../components/TheTabArancel.vue';
import TheOtros from '../components/TheOtros.vue';

export default {
  name: 'index',
  components: {
    Crear,
    Asignar,
    TheTablCategoriaLinae,
    TheTabClasificacionCie,
    TheTabArancel,
    TheOtros,
  },
  data() {
    return {
      componentShow: 'EstructuraConsultorio',
      optionSelected: 'Crear',
      isSalaCama: false,
    };
  },
  methods: {
    handleSalaCamaEmitido(value) {
      this.isSalaCama = value;
    },
    ...mapActions('salaCama', [
      'saveHoraSalida',
    ]),
    ...mapMutations('salaCama', [
      'setHoraSalida',
    ]),
  },
  computed: {
    isEstructuraConsultorio() {
      return this.componentShow === 'EstructuraConsultorio';
    },
    isCrear() {
      return (this.optionSelected === 'Crear');
    },
    isAsignar() {
      return (this.optionSelected === 'Asignar');
    },
    isDiagnosticoCie() {
      return (this.componentShow === 'DiagnosticoCie');
    },
    isArancel() {
      return (this.componentShow === 'Arancel');
    },
    isCategoriaLinae() {
      return (this.componentShow === 'CategoriaLinae');
    },
    isOtros() {
      return (this.componentShow === 'Otros');
    },
    ...mapState('salaCama', [
      'horaSalida',
    ]),
    horaSalidaComputed: {
      get() {
        return this.horaSalida; // Devuelve el valor del state
      },
      set(value) {
        this.setHoraSalida(value); // Llama a la mutación para establecer el nuevo valor
      },
    },
  },
};
</script>

<style scoped>
</style>
