<template>
  <div>
    <div class="breadcrumb">
      <h1>Nuevo Arancel</h1>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <button
                  class="btn th-custom-color mr-2"
                  :disabled="buttonStoreDisabled"
                  @click="guardarArancel"
                >
                  Guardar
                </button>
                <button
                  class="btn btn-secondary"
                  @click="goToListarUI"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mx-5">
              <div class="col-sm-1 pr-0 text-right">
                <label
                  for="paquete"
                  class="col-form-label"
                >
                  Paquete: <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-sm-2">
                <input
                  id="paquete"
                  v-model="paquete"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-2 pr-0 text-right">
                <label
                  for="comboCategoria"
                  class="col-form-label"
                >
                  Categoria Arancel: <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-sm-3 pr-0">
                <multiselect
                  id="comboCategoria"
                  v-model="categoriaSelected"
                  :options="listaCategorias"
                  label="nombre"
                  track-by="id"
                  placeholder="Seleccionar"
                  select-label=""
                  deselect-label="X"
                >
                  <span slot="caret" v-if="!(categoriaSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
            </div>
            <div class="separator-breadcrumb border-top mt-4"></div>
            <div class="row">
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-3 pr-0 text-right">
                    <label
                      for="cargo"
                      class="col-form-label"
                    >
                      Cargo:
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <input
                      id="cargo"
                      v-model="cargo"
                      autocomplete="off"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-sm-2 text-center">
                    <button
                      class="btn w-100"
                      :class="getColorButton"
                      @click="changeTypeButton"
                    >{{getTextButton}}</button>
                  </div>
                  <div class="col-sm-1 pr-0 text-right">
                    <label
                      for="importe"
                      class="col-form-label"
                    >
                      Importe:
                    </label>
                  </div>
                  <div class="col-sm-2 pr-0">
                    <vue-numeric
                      id="importe"
                      class="form-control"
                      autocomplete="off"
                      v-model="importe"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                  <div class="col-sm-1 pr-0 text-right">
                    <label
                      for="seguro"
                      class="col-form-label"
                    >
                      Seguro:
                    </label>
                  </div>
                  <div class="col-sm-2 pr-0">
                    <vue-numeric
                      id="seguro"
                      class="form-control"
                      v-model="importe_seguro"
                      autocomplete="off"
                      separator=","
                      :precision="2"
                      placeholder=""
                    />
                  </div>
                  <div class="col-sm-2 pr-0">
                    <multiselect
                      v-model="monedaSelected"
                      :options="listaMonedas"
                      label="name"
                      track-by="id"
                      placeholder="Seleccionar"
                      select-label=""
                      deselect-label="X"
                    >
                      <span slot="caret" v-if="!(monedaSelected===null)"></span>
                      <template slot="singleLabel" slot-scope="props">
                        <small>{{props.option.name}}</small>
                      </template>
                      <template slot="option" slot-scope="props">
                        <small>{{props.option.name}}</small>
                      </template>
                      <span slot="noResult">Sin Resultados</span>
                      <span slot="noOptions">Lista Vacia</span>
                      <template slot="option" slot-scope="props">
                        <small>{{ props.option.name }}</small>
                      </template>
                      <template slot="singleLabel" slot-scope="props">
                        <small class="itemSelected-combo">{{ props.option.name }}</small>
                      </template>
                    </multiselect>
                  </div>
                  <div class="col-sm-2">
                    <button
                      class="btn btn-success btn-block text-center"
                      @click="addItemToTable"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12">
                <table class="table table-bordered table-hover" width="100%">
                  <thead>
                    <tr>
                      <!-- <th width="3%"></th>
                      <th width="3%"></th> -->
                      <th width="5%" class="text-center">ID</th>
                      <th width="27%" class="text-center">Cargo</th>
                      <th width="20%" class="text-center">Tipo</th>
                      <th width="10%" class="text-center">Importe</th>
                      <th width="10%" class="text-center">Seguro</th>
                      <th width="15%" class="text-center">Moneda</th>
                      <th width="7%" class="text-center">Vinculado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in itemList" :key="index">
                      <!-- <td></td>
                      <td></td> -->
                      <td></td>
                      <td class="text-center"> {{ item.cargo }} </td>
                      <td class="text-center"> {{ item.tipo }} </td>
                      <td class="text-center">
                        {{ item.importe | numeral('0,0.00')}}
                      </td>
                      <td class="text-center">
                        {{ item.seguro | numeral('0,0.00')}}
                      </td>
                      <td class="text-center"> {{ item.moneda }} </td>
                      <td class="text-center"> {{ item.vinculado }} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheNuevoArancel',
  components: {
    Multiselect,
    VueNumeric,
  },
  data() {
    return {
      buttonStoreDisabled: false,
      paquete: '',
      categoriaSelected: null,
      listaCategorias: [],
      cargo: '',
      importe: 0,
      importe_seguro: 0,
      monedaSelected: null,
      listaMonedas: [],
      itemList: [],
      typeButton: 1,
    };
  },
  created() {
    this.listarCategorias();
    this.cargarDatosIniciales();
  },
  methods: {
    async cargarDatosIniciales() {
      try {
        const response = await axios.get('/clinic/parametros/arancel/create');
        this.listaMonedas = response.data.data.monedas;
        this.monedaSelected = this.listaMonedas.find((el) => el.is_principal === 1);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async listarCategorias() {
      try {
        const RESPONSE = await axios.get('/clinic/parametros/categoria_arancel');
        this.listaCategorias = RESPONSE.data.data.categoria_arancel;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    clearformTable() {
      this.cargo = '';
      this.typeButton = 1;
      this.importe = 0;
      this.importe_seguro = 0;
      this.monedaSelected = this.listaMonedas.find((el) => el.is_principal === 1);
    },
    addItemToTable() {
      if (this.cargo === '') {
        util.showNotify('Elija un Cargo', 'warn');
        return;
      }
      if (this.monedaSelected === null) {
        util.showNotify('Elija la moneda', 'warn');
        return;
      }

      const ITEM = {
        id: 0,
        cargo: this.cargo,
        tipo: this.getTextButton,
        importe: this.importe,
        seguro: this.importe_seguro,
        moneda_id: this.monedaSelected.id,
        moneda: this.monedaSelected.name,
      };
      this.itemList.push(ITEM);
      this.clearformTable();
    },
    async guardarArancel() {
      if (this.paquete === '') {
        util.showNotify('Elija un Paquete', 'warn');
        return;
      }
      if (this.categoriaSelected === null) {
        util.showNotify('Elija una Categoría', 'warn');
        return;
      }

      this.buttonStoreDisabled = true;
      const REQUEST = {
        paquete: this.paquete,
        categoria_arancel_id: this.categoriaSelected.id,
        detallesArancel: this.itemList,
      };
      try {
        const RESPONSE = await axios.post('/clinic/parametros/arancel/store', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.goToListarUI();
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        this.buttonStoreDisabled = false;
      }
    },
    changeTypeButton() {
      if (this.typeButton === 3) {
        this.typeButton = 1;
      } else {
        this.typeButton += 1;
      }
    },
    goToListarUI() {
      this.$emit('goToListar', true);
    },
  },
  computed: {
    getTextButton() {
      let text = '';
      switch (this.typeButton) {
        case 1:
          text = 'Médico';
          break;
        case 2:
          text = 'Clínica';
          break;
        case 3:
          text = 'Insumos';
          break;

        default:
          break;
      }
      return text;
    },
    getColorButton() {
      let classColor = '';
      switch (this.typeButton) {
        case 1:
          classColor = 'btn-warning';
          break;
        case 2:
          classColor = 'btn-success';
          break;
        case 3:
          classColor = 'btn-orange';
          break;

        default:
          break;
      }
      return classColor;
    },
  },
};
</script>

<style scoped>
.itemSelected-combo{
  position: absolute;
  width: 125%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
