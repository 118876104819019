<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Modificar Clasificacion
            <span class="h4 pl-2">
              N°: {{ numero }}
            </span>
          </h1>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="col-sm-5">
              <button class="btn th-custom-color text-dark mr-2"
              type="button"
              @click="updateClasificacion"
              :disabled="disabledButton">
              Guardar
              </button>
              <button class="btn btn-secondary text-dark"
              type="button"
              @click="componentShow = 'TheIndexClasificacionCie'">
              Regresar
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <label for="nombre_clasificacion"
              class="col-sm-2 col-form-label text-right">
              Grupo:
              <span
                  class="text-danger">*
              </span>
              </label>
              <div class="col-sm-2">
                <input type="text" class="form-control"
                v-model="codigoClasificacion"
                placeholder="Ingrese el codigo" required>
              </div>
              <label for="descripcion_grupo"
              class="col-sm-2 col-form-label text-right">
                Producto de Salud:
                <span
                  class="text-danger">*
                </span>
              </label>
              <div class="col-sm-3">
                <input type="text" class="form-control"
                v-model="nombreClasificacion"
                placeholder="Ingrese el nombre de la clasificacion" required>
              </div>
            </div>
            <hr>
            <div class="form-group row">
              <label for="nombre_sub_clasificacion"
              class="col-sm-2 col-form-label text-right">
              Codigo Cie:
              <span
                class="text-danger">*
              </span>
              </label>
              <div class="col-sm-2">
                <input type="text" class="form-control"
                v-model="codigoSubClasificacion"
                placeholder="Ingrese el codigo" required>
              </div>
              <label for="descripcion_sub_clasificacion"
              class="col-sm-2 col-form-label text-right">
                Nombre Diagnostico:
                <span
                  class="text-danger">*
                </span>
              </label>
              <div class="col-sm-3">
                <input type="text" class="form-control"
                v-model="nombreSubClasificacion"
                placeholder="Ingrese el nombre de la sub clasificacion" required>
              </div>
              <div class="col-sm-3">
                <button class="btn th-custom-color text-dark"
                type="button"
                @click="addSubClasificacion()"
                >Agregar</button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div style="display: flex; width: 100%; text-align:center">
                  <table class="table table-bordered table-hover" width="100%">
                    <thead>
                      <tr>
                        <th width="3%"></th>
                        <th width="3%"></th>
                        <th width="10%" class="text-center">ID</th>
                        <th width="20%" class="text-center">Codigo Cie</th>
                        <th width="45%">Nombre Diagnostico</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(subClasificion, index) in listaSubClasificacionsFiltereds"
                      :key="index">
                        <td class="text-center">
                          <i class="nav-icon i-Pen-5 font-weight-bold text-success"
                          style="cursor: pointer"
                          @click="edit(index)"
                          ></i>
                        </td>
                        <td class="text-center">
                          <a class="delete text-danger"
                          @click="eliminar(subClasificion)">
                            <i class="nav-icon i-Close-Window font-weight-bold"
                            style="cursor: pointer"></i>
                          </a>
                        </td>
                        <td v-if="subClasificion.id === 0"> S/N </td>
                        <td v-else> {{ subClasificion.numero_interno }} </td>
                        <td>
                          <input type="text"
                          v-model="subClasificion.codigo"
                          :readonly="readInputs[index].value"
                          class="form-control">
                        </td>
                        <td>
                          <input type="text" v-model="subClasificion.nombre"
                          :readonly="readInputs[index].value"
                          class="form-control">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  name: 'TheEditClasificacionCie',
  data() {
    return {
      disabledButton: false,
    };
  },
  methods: {
    addSubClasificacion() {
      if (this.validarAddSubClasificacion()) {
        this.listaSubClasificacions.push({
          id: 0,
          numero: this.readInputs.length + 1,
          nombre: this.nombreSubClasificacion,
          codigo: this.codigoSubClasificacion,
          estado: true,
          key: `CL-${new Date().getTime()}`,
        });
        this.readInputs.push({ value: true });
        this.limpiarDataSubClasificacion();
      }
    },
    limpiarDataSubClasificacion() {
      this.codigoSubClasificacion = null;
      this.nombreSubClasificacion = null;
    },
    validarAddSubClasificacion() {
      let valido = true;
      if (!this.nombreSubClasificacion) {
        valido = false;
        util.showNotify('El nombre de la sub clasificacion es requerido', 'warn');
      }
      if (!this.codigoSubClasificacion) {
        valido = false;
        util.showNotify('El codigo de la sub clasificacion es requerido', 'warn');
      }
      const codigo = this.listaSubClasificacions.find((el) => (
        el.codigo === this.codigoSubClasificacion && el.estado === true));
      if (codigo) {
        valido = false;
        util.showNotify('El codigo de la sub clasificacion ya existe en el detalle', 'warn');
      }
      return valido;
    },
    validar() {
      let valido = true;
      if (!this.codigoClasificacion) {
        valido = false;
        util.showNotify('El codigo de la clasificacion es requerido', 'warn');
      }
      if (!this.nombreClasificacion) {
        valido = false;
        util.showNotify('El nombre de la clasificacion es requerido', 'warn');
      }
      if (this.listaSubClasificacions.length === 0) {
        valido = false;
        util.showNotify('Debe agregar al menos una sub clasificacion', 'warn');
      }
      return valido;
    },
    eliminar(item) {
      const INDEX = this.listaSubClasificacions.findIndex((el) => el.key === item.key);
      if (item.id === 0) {
        this.listaSubClasificacions.splice(INDEX, 1);
      } else {
        // cambiar el estado a false
        this.listaSubClasificacions[INDEX].estado = false;
      }
      this.readInputs.splice(INDEX, 1);
    },
    edit(row) {
      this.readInputs[row].value = false;
    },
    ...mapActions('formularioClasificacion', [
      'resetFormularioClasificacionStore',
    ]),
    ...mapActions('clasificacionApis', [
      'callUpdateApi',
      'callIndexApi',
    ]),
    async updateClasificacion() {
      this.disabledButton = true;
      const UPDATE_OK = await this.callUpdateApi(this.itemId);
      if (UPDATE_OK) {
        this.callIndexApi();
      }
      this.disabledButton = false;
    },
  },
  beforeDestroy() {
    this.resetFormularioClasificacionStore();
  },
  computed: {
    ...mapFields('formularioClasificacion', [
      'componentShow',
      'nombreClasificacion',
      'codigoClasificacion',
      'listaSubClasificacions',
      'codigoSubClasificacion',
      'nombreSubClasificacion',
      'readInputs',
      'isLoading',
      'numero',
      'itemId',
    ]),
    listaSubClasificacionsFiltereds() {
      return this.listaSubClasificacions.filter((item) => item.estado === true);
    },
  },
  watch: {},
};
</script>
