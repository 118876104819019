<template>
  <span>
    <!-- <button class="btn btn-white border border-light px-4" v-b-modal.modal-especialidad> -->
    <button :class="classButton" v-b-modal="modal">
      {{ title }}
    </button>
    <b-modal size='xl' :id="modal" :title="title" hide-footer>
      <div class="row mb-4">
        <div class="col-sm-9 pr-0">
          <input type="text" class="form-control" v-model="especialidad"
            @keyup.enter="agregarEspecialidad"
            :placeholder="holder"
            autocomplete="off" :disabled="inputDisabled" :maxlength="maxLengthInput">
        </div>
        <div class="col-sm-3">
          <button class="btn btn-warning" @click="agregarEspecialidad" :disabled="inputDisabled">
            Agregar
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <b-table responsive class="table table-striped table-bordered table-hover"
            :fields="fieldsTablaModal"
            :items="listaEspecialidad">
            <template #cell(accion)="data">
              <div class="pt-2">
                <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                style="cursor: pointer" @click="eliminarEspecialidad(data)"></i>
              </div>
            </template>
            <template #cell(nombre)="data">
              <label @click="data.item.editable = true" style="cursor: pointer"
                v-show="!data.item.editable" class="col-form-label">
                {{data.item.nombre}}
              </label>
              <input v-show="data.item.editable" :maxlength="maxLengthInput"
               @blur="actualizarEspecialidad(data.item);data.item.editable = false"
                type="text" class="form-control" v-model="data.item.nombre">
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheMultiModalMedico',
  props: {
    title: {
      required: true,
    },
    classButton: {
      required: true,
    },
    nameUrl: {
      required: true,
    },
    modal: {
      required: true,
    },
    data: {
      required: true,
    },
    holder: {
      required: true,
    },
  },
  data() {
    return {
      especialidad: '',
      inputDisabled: false,
      maxLengthInput: 191,
      listaEspecialidad: [],
      fieldsTablaModal: [
        {
          key: 'accion', label: '', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '5%' },
        },
        {
          key: 'nombre', label: 'Nombre', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '95%' },
        },
      ],
    };
  },
  created() {
    this.listarEspecialidades();
  },
  methods: {
    async agregarEspecialidad() {
      this.inputDisabled = true;
      if (this.crearEspecialidad()) {
        this.especialidad = '';
      }
      this.inputDisabled = false;
    },
    async actualizarEspecialidad(item) {
      const objEspecialidad = { nombre: item.nombre };
      try {
        const response = await axios.put(`/clinic/doctors/${this.nameUrl}/${item.id}`, objEspecialidad);
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async eliminarEspecialidad(elemento) {
      try {
        this.listaEspecialidad = this.listaEspecialidad.filter((item) => (
          item.id !== elemento.item.id
        ));
        const response = await axios.delete(`/clinic/doctors/${this.nameUrl}/${elemento.item.id}`);
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarEspecialidades();
      }
    },
    async listarEspecialidades() {
      try {
        const response = await axios.get(`/clinic/doctors/${this.nameUrl}`);
        const arrayEsp = response.data.data[this.data];
        arrayEsp.forEach((item, index) => { arrayEsp[index].editable = false; });
        this.listaEspecialidad = arrayEsp;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async crearEspecialidad() {
      const objEspecialidad = { nombre: this.especialidad };
      let estado = false;
      try {
        const response = await axios.post(`/clinic/doctors/${this.nameUrl}`, objEspecialidad);
        await this.listarEspecialidades();
        estado = true;
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        const mensajes = error.response.data.data.nombre;
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      return estado;
    },
  },
};
</script>

<style scoped>
</style>
