<template>
  <div>
    <TheIndexClasificacionCie v-if="isTheIndexClasificacionCie"/>
    <TheNuevoClasificacionCie v-if="isTheNuevoClasificacionCie"/>
    <TheEditClasificacionCie v-if="isTheEditClasificacionCie"/>
    <TheImportarClasificacionCie v-if="isTheImportClasificacion"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import TheIndexClasificacionCie from './TheIndexClasificacionCie.vue';
import TheNuevoClasificacionCie from './TheNuevoClasificacionCie.vue';
import TheEditClasificacionCie from './TheEditClasificacionCie.vue';
import TheImportarClasificacionCie from './TheImportarClasificacionCie.vue';

export default {
  name: 'TheTabClasificacionCie',
  data() {
    return {
    };
  },
  beforeDestroy() {
    this.resetFormularioClasificacion();
  },
  methods: {
    ...mapActions('formularioClasificacion', [
      'resetFormularioClasificacion',
    ]),
  },
  computed: {
    isTheIndexClasificacionCie() {
      return (this.componentShow === 'TheIndexClasificacionCie');
    },
    isTheNuevoClasificacionCie() {
      return (this.componentShow === 'TheNuevoClasificacionCie');
    },
    isTheEditClasificacionCie() {
      return (this.componentShow === 'TheEditClasificacionCie');
    },
    isTheImportClasificacion() {
      return (this.componentShow === 'TheImportClasificacion');
    },
    ...mapFields('formularioClasificacion', [
      'componentShow',
    ]),
  },
  components: {
    TheIndexClasificacionCie,
    TheNuevoClasificacionCie,
    TheEditClasificacionCie,
    TheImportarClasificacionCie,
  },
};
</script>
