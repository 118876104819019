<template>
  <b-modal id="modal-categoriaMedico"
    size="md"
    title="Categoria Medico"
    hide-footer
  >
    <div class="row mb-4">
      <div class="col-sm-9 pr-0">
        <input
          type="text"
          class="form-control"
          v-model="categoria"
          @keyup.enter="agregarCategoria"
          placeholder="Ingrese una Categoria"
          :disabled="inputDisabled"
        >
      </div>
      <div class="col-sm-3">
        <button
          class="btn btn-warning"
          @click="agregarCategoria"
          :disabled="inputDisabled">
          Agregar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <b-table responsive class="table table-striped table-bordered table-hover"
          :fields="fieldsTablaModal"
          :items="listaCategorias"
        >
          <template #cell(accion)="row">
            <div class="pt-2 text-center">
              <i
                class="nav-icon i-Close-Window text-danger font-weight-bold"
                style="cursor: pointer"
                @click="eliminarCategoria(row.item)"
              ></i>
            </div>
          </template>
          <template #cell(numero)="row">
            <label
              style="cursor: pointer"
              class="col-form-label">
              {{row.item.numero}}
            </label>
          </template>
          <template #cell(nombre)="row">
            <label @click="row.item.editable = true"
              v-show="!row.item.editable"
              style="cursor: pointer"
              class="col-form-label">
              {{row.item.nombre}}
            </label>
            <input
              v-model="row.item.nombre"
              v-show="row.item.editable"
              @blur="actualizarCategoria(row.item);row.item.editable = false"
              type="text"
              class="form-control"
            >
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ModalCategoriaMedico',
  data() {
    return {
      categoria: '',
      inputDisabled: false,
      fieldsTablaModal: [
        {
          key: 'accion', label: '', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'numero', label: 'Id', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', tdClass: 'py-1', thClass: 'border-bottom', thStyle: { width: '80%' },
        },
      ],
      listaCategorias: [],
    };
  },
  created() {
    this.listarCategorias();
  },
  methods: {
    async listarCategorias() {
      try {
        const RESPONSE = await axios.get('/clinic/doctors/categoria_medico');
        const LISTA_TEMPORAL = RESPONSE.data.data.categoria_medico;
        LISTA_TEMPORAL.forEach((item, index) => { LISTA_TEMPORAL[index].editable = false; });
        this.listaCategorias = LISTA_TEMPORAL;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async crearCategoria() {
      const REQUEST = { nombre: this.categoria };
      let estado = false;
      try {
        const response = await axios.post('/clinic/doctors/categoria_medico', REQUEST);
        await this.listarCategorias();
        estado = true;
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        const mensajes = error.response.data.data.nombre;
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      return estado;
    },
    agregarCategoria() {
      this.inputDisabled = true;
      if (this.crearCategoria()) {
        this.categoria = '';
      }
      this.inputDisabled = false;
    },
    async actualizarCategoria(item) {
      const REQUEST = { nombre: item.nombre };
      try {
        const RESPONSE = await axios.put(`/clinic/doctors/categoria_medico/${item.id}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async eliminarCategoria(fila) {
      try {
        this.listaCategorias = this.listaCategorias.filter((item) => (
          item.id !== fila.id
        ));
        const RESPONSE = await axios.delete(`/clinic/doctors/categoria_medico/${fila.id}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarCategorias();
      }
    },
  },
};
</script>

<style scoped>
</style>
