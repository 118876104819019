<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-4 d-flex">
                <button
                  class="btn th-custom-color px-4 rounded mr-2"
                  @click="componentShow='TheNuevoClasificacionCie'"
                >
                  Nuevo
                </button>
                <button
                  class="btn th-custom-color px-4 rounded mr-2"
                  @click="componentShow='TheImportClasificacion'"
                >
                  Importar
                </button>
                <b-input-group>
                  <input
                    type="text"
                    v-model="nombreDiagnostico"
                    class="form-control"
                    :placeholder="!nombreDiagnostico ? 'S/N': ''"
                    @keyup.enter="updateNombreDiagnostico"
                  >
                  <b-input-group-append>
                    <button
                      class="btn btn-light px-2"
                      title="Actualizar Nombre Diagnostico"
                      @click="updateNombreDiagnostico"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        class="bi bi-floppy"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 2H9v3h2z"/>
                        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                          2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                          0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                          0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                          0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                          7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                          .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                          0 0 0-.5.5z"
                        />
                      </svg>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm-1 text-right">
                <label class="col-form-label">
                  Cantidad:
                </label>
              </div>
              <div class="col-sm-1 pl-0">
                <multiselect
                  v-model="cantidadSelected"
                  :options="cantidades"
                  placeholder=""
                  select-label=""
                  deselect-label="X"
                  @input="storeCantidad"
                  :allow-empty="false"
                >
                  <span slot="caret" v-if="!(cantidadSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                </multiselect>
              </div>
              <div class="col-sm-5">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input id="filterbyState"
                      :checked="estado"
                      v-model="estado"
                      @change="listarRegistros"
                      type="checkbox">
                      <span class="slider"></span>
                      <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="porPagina"
                    :options="paginaOpciones"
                    @change="listarRegistros">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="busqueda"
                      autocomplete="off"
                      placeholder="Buscar"
                      @keyup="filtrarBusqueda"
                    >
                  </div>
                </div>
              </div>
              <!-- tabla -->
              <b-table class="table table-striped table-hover"
                :fields="fields" :items="listarTabla"
                :busy="isLoading"
                :per-page="porPagina"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                    <strong>Cargando...</strong>
                  </div>
                </template>
                <template v-if="estado" #cell(editar)="data">
                    <i
                      @click="editClasificacion(data.item)"
                      class="nav-icon i-Pen-5 text-success font-weight-bold"
                      style="cursor: pointer"
                    ></i>
                </template>
                <template v-if="estado" #cell(eliminar)="data">
                  <i
                    class="nav-icon i-Close-Window font-weight-bold text-danger"
                    @click="onClickEliminarIcon(data.item)"
                    style="cursor: pointer"
                  ></i>
                </template>
              </b-table>
            </div>
            <!-- Paginacion -->
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="paginaActual"
                  :total-rows="totalFilas"
                  :per-page="porPagina"
                  @input="listarRegistros"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <!-- Fin Paginacion -->
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-eliminar"
      :title="'Seguro que quieres eliminar la clasificacion - N° '+selectedNro+'?'"
      hide-footer>
      <div class="row">
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
            Eliminar
          </button>
        </div>
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-outline-dark"
          @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect';

export default {
  name: 'TheClasificacionIndex',
  components: {
    Multiselect,
  },
  data() {
    return {
      delayTimeout: null,
      selectedNro: null,
      selectId: null,
    };
  },
  created() {
    this.listarRegistros();
  },
  methods: {
    async listarRegistros() {
      await this.callIndexApi();
      this.isLoading = false;
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarRegistros, DELAY);
    },
    onClickEliminarIcon(item) {
      this.selectedNro = item.numero;
      this.selectId = item.id;
      this.$bvModal.show('modal-eliminar');
    },
    async eliminarRegistro() {
      const DELETE_OK = await this.callDestroyApi(this.selectId);
      if (DELETE_OK) {
        this.$bvModal.hide('modal-eliminar');
        this.selectedNro = null;
        this.selectId = null;
      }
    },
    async editClasificacion(item) {
      const EDIT_OK = await this.callEditApi(item.id);
      if (EDIT_OK) {
        this.componentShow = 'TheEditClasificacionCie';
      }
    },
    storeCantidad() {
      this.updateCantidadClasificacionCIE();
    },
    ...mapActions('clasificacionApis', [
      'callIndexApi',
      'callDestroyApi',
      'callEditApi',
      'updateNombreDiagnostico',
      'updateCantidadClasificacionCIE',
    ]),
  },
  computed: {
    ...mapFields('formularioClasificacion', [
      'busqueda',
      'listarTabla',
      'estado',
      'isLoading',
      'fields',
      'sortDesc',
      'sortBy',
      'paginaOpciones',
      'paginaActual',
      'porPagina',
      'totalFilas',
      'componentShow',
      'nombreDiagnostico',
      'cantidadSelected',
      'cantidades',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
