<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-9">
        <div class="row">
          <button
            class="btn border border-light col-sm-2 mx-3"
            :class="isClase ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'Clase'"
          >
            <b>Clase</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isPiso ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'Piso'"
          >
            <b>Piso</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isSala ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'Sala'"
          >
            <b>Sala</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isCama ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'Cama'"
          >
            <b>Cama</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isConsultorio ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'Consultorio'"
          >
            <b>Consultorio</b>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <Piso v-if="isPiso"/>
      <Sala v-if="isSala"/>
      <Cama v-if="isCama"/>
      <Clase v-if="isClase"/>
      <Consultorio v-if="isConsultorio"/>
    </div>
  </div>
</template>

<script>
import Piso from './ThePiso.vue';
import Sala from './TheSala.vue';
import Cama from './TheCama.vue';
import Clase from './TheClase.vue';
import Consultorio from './TheConsultorio.vue';

export default {
  name: 'Crear',
  components: {
    Piso,
    Sala,
    Cama,
    Clase,
    Consultorio,
  },
  data() {
    return {
      componentShow: 'Clase',
    };
  },
  computed: {
    isPiso() {
      return (this.componentShow === 'Piso');
    },
    isSala() {
      return (this.componentShow === 'Sala');
    },
    isCama() {
      return (this.componentShow === 'Cama');
    },
    isClase() {
      return (this.componentShow === 'Clase');
    },
    isConsultorio() {
      return (this.componentShow === 'Consultorio');
    },
  },
};
</script>

<style scoped>
</style>
