<template>
  <div>
    <TheIndexCategoriaLinae v-if="isTheIndexCategoriaLinae"/>
    <TheNuevoCategoriaLinae v-if="isTheNuevoCategoriaLinae"/>
    <TheEditCategoriaLinae  v-if="isTheEditCategoriaLinae"/>
    <TheImportCategoriaLinae v-if="isTheImportCategoriaLinae" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import TheIndexCategoriaLinae from './TheIndexCategoriaLinae.vue';
import TheNuevoCategoriaLinae from './TheNuevoCategoriaLinae.vue';
import TheEditCategoriaLinae from './TheEditCategoriaLinae.vue';
import TheImportCategoriaLinae from './TheImportarCategorialLinae.vue';

export default {
  name: 'TheTabCategoriaLinae',
  data() {
    return {
    };
  },
  beforeDestroy() {
    this.resetFormularioCategoriaLinae();
  },
  methods: {
    ...mapActions('formularioCategoriaLinae', [
      'resetFormularioCategoriaLinae',
    ]),
  },
  computed: {
    isTheIndexCategoriaLinae() {
      return (this.componentShow === 'TheIndexCategoriaLinae');
    },
    isTheNuevoCategoriaLinae() {
      return (this.componentShow === 'TheNuevoCategoriaLinae');
    },
    isTheEditCategoriaLinae() {
      return (this.componentShow === 'TheEditCategoriaLinae');
    },
    isTheImportCategoriaLinae() {
      return (this.componentShow === 'TheImportCategoriaLinae');
    },
    ...mapFields('formularioCategoriaLinae', [
      'componentShow',
    ]),
  },
  components: {
    TheIndexCategoriaLinae,
    TheNuevoCategoriaLinae,
    TheEditCategoriaLinae,
    TheImportCategoriaLinae,
  },
};
</script>
