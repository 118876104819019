<template>
  <div>
    <div class="breadcrumb">
      <h1>Importar Categoria-SubCategorias</h1>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                  <label class="btn th-custom-color px-4 rounded col-form-label mr-2">
                    <input type="file" hidden
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-excel"
                      @change="handleFileUpload()"
                      id="file" ref="file">
                    Seleccionar Archivo
                  </label>
                  <button class="btn th-custom-color px-4 rounded mr-2"
                  v-if="existeArchivo"
                  @click="subir">
                    Subir
                  </button>
                  <button class="btn btn-default rounded"
                  @click="componentShow='TheIndexCategoriaLinae'">
                  Regresar
                  </button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="isUploading">
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-sm-6">
                <div class="btn-group dropright">
                  <button type="button" class="btn btn-secondary"
                  @click="downloadPlantillaNewDatos">
                    Descargar plantilla de categoria linae
                  </button>
                  <button class="btn btn-success ml-3"
                  v-if="categorias.length > 0"
                  @click="guardar">Guardar</button>
                </div>
              </div>
            </div>
            <div class="row" v-if="categorias.length === 0">
              <div class="col-sm-12">
                <div class="alert alert-info">
                  <div class="text-center">
                    <p align="center"><b>¡IMPORTANTE!</b></p>
                    <p align="center">Al importar mantener los titulos y las columnas
                      en sus respectivas ubicaciones</p>
                    <p>Para importar las Categoria LINAE, debe introducir la categoria
                      con su respectiva sub categoria,
                      en caso de que una categoria tuviera varias sub categoria es necesario
                      que la categoria se repita.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-for="(error, index) in errors" :key="index">
              <div class="col-sm-12">
                <div class="alert alert-warning">
                  <button type="button" class="close" data-dismiss="alert"
                  data-auto-dismiss="2000" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <strong>..Verifique en : {{error}}</strong>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="categorias.length > 0">
              <div class="col-sm-12">
                <b-table class="table table-striped table-hover"
                :fields="fieldsCategorias"
                :items="tableCategorias"
                :per-page="perPageCategorias">
                <template #cell(eliminar)="items">
                  <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                    @click="eliminarItem(items.index)"
                    style="cursor: pointer"></i>
                </template>
                </b-table>
              </div>
              <div class="col-sm-4 offset-sm-8">
                <b-pagination
                  v-model="currentPageCategorias"
                  :total-rows="categorias.length"
                  :per-page="perPageCategorias"
                  @click.native="listarTablaCategorias"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheImportCategoria',
  data() {
    return {
      errors: [],
      categorias: [],
      file: null,
      existeArchivo: false,
      fieldsCategorias: [
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'nombre_categoria', label: 'Nombre Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '20%' }, sortable: true,
        },
        {
          key: 'codigo_categoria', label: 'Codigo Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'nombre_sub_categoria', label: 'Nombre Sub Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '20%' }, sortable: true,
        },
        {
          key: 'codigo_sub_categoria', label: 'Codigo Sub Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
      ],
      perPageCategorias: 100,
      currentPageCategorias: 1,
      tableCategorias: [],
      isUploading: false,
    };
  },
  methods: {
    handleFileUpload() {
      this.existeArchivo = true;
      const [files] = this.$refs.file.files;
      this.file = files;
    },
    downloadPlantillaNewDatos() {
      axios.get('/clinic/parametros/categoria_linae/load_massive/download', { responseType: 'blob' })
        .then((response) => {
          const BLOB = new Blob([response.data], { type: 'data:application/vnd.ms-excel' });
          const DOWNLOAD_URL = URL.createObjectURL(BLOB);
          const a = document.createElement('a');
          a.href = DOWNLOAD_URL;
          a.download = 'Formato para importar nueva categorias.xlsx';
          document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          util.showNotify(error.response.data.message, 'error');
        });
    },
    subir() {
      this.isUploading = true;
      this.currentPageCategorias = 1;
      this.errors = [];
      this.categorias = [];
      const DATA = new FormData();
      DATA.append('file', this.file);
      axios.post('/clinic/parametros/categoria_linae/load_massive/upload_import', DATA)
        .then((response) => {
          this.categorias = response.data.data.categorias;
          const FIN = this.currentPageCategorias * this.perPageCategorias;
          this.tableCategorias = this.categorias.slice(0, FIN);
          util.showNotify(response.data.message, 'success');
        })
        .catch((error) => {
          this.tableCategorias = [];
          this.categorias = [];
          if (error.message === 'Network Error') {
            util.showNotify('Error al subir un archivo modificado, refresque la pagina e intente nuevamente.', 'error');
            return;
          }
          if (error.response.data.error === 2001) {
            util.showNotify(error.response.data.message, 'error');
          } else {
            this.errors = error.response.data ? error.response.data.data : { message: 'Verifique su Archivo', error: 'Error al Subir' };
            const UNICOS = new Set(this.errors);
            this.errors = UNICOS;
          }
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    async guardar() {
      this.isUploading = true;
      try {
        const REQUEST = {
          categorias: this.categorias,
        };
        const RESPONSE = await axios.post('/clinic/parametros/categoria_linae/load_massive/store_import', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isUploading = false;
        this.componentShow = 'TheIndexCategoriaLinae';
      }
    },
    listarTablaCategorias() {
      const INICIO = (this.currentPageCategorias - 1) * this.perPageCategorias;
      const FIN = this.currentPageCategorias * this.perPageCategorias;
      this.tableCategorias = this.categorias.slice(INICIO, FIN);
    },
    eliminarItem(index) {
      const REFERENCIA = (this.currentPageCategorias - 1) * this.perPageCategorias;
      this.tableCategorias.splice(index, 1);
      this.categorias.splice(index + REFERENCIA, 1);
    },
  },
  computed: {
    ...mapFields('formularioCategoriaLinae', [
      'componentShow',
    ]),
  },
};
</script>
