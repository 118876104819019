<template>
    <b-modal
    :visible="modalShow"
    @hide="handleOutsideClick"
    centered
    :header-class="{ 'bg-danger': existeEnLista }"
>
    <div>{{ message }}</div>
    <template #modal-footer>
      <button v-if="!existeEnLista" class="btn btn-danger" @click="$emit('confirm')">Eliminar</button>
      <button v-if="!existeEnLista" class="btn btn-outline-dark" @click="cancelar">Cancelar</button>
    </template>
</b-modal>
</template>

<script>
export default {
  name: 'ModalConfirmacion',
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    existeEnLista: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleOutsideClick() {
      this.$emit('cancel');
    },
    cancelar() {
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped>
</style>
