<template>
  <div>
    <div class="breadcrumb">
      <h1>Importar Clasificacion-SubClasificacion</h1>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                  <label class="btn th-custom-color px-4 rounded col-form-label mr-2">
                    <input type="file" hidden
                      id="file" ref="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-excel"
                      @change="handleFileUpload()">
                    Seleccionar Archivo
                  </label>
                  <button class="btn th-custom-color px-4 rounded mr-2"
                  v-if="existeArchivo"
                  @click="subir">
                    Subir
                  </button>
                  <button class="btn btn-default rounded"
                  @click="componentShow='TheIndexClasificacionCie'">
                  Regresar
                  </button>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="isUploading">
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-sm-6">
                <div class="btn-group dropright">
                  <button type="button" class="btn btn-secondary"
                  @click="downloadPlantillaNewDatos">
                    Descargar plantilla de ClasificacionCIE
                  </button>
                  <button class="btn btn-success ml-3"
                  v-if="clasificaciones.length > 0"
                  @click="guardar()">Guardar</button>
                </div>
              </div>
            </div>
            <div class="row" v-if="clasificaciones.length === 0">
              <div class="col-sm-12">
                <div class="alert alert-info">
                  <div class="text-center">
                    <p align="center"><b>¡IMPORTANTE!</b></p>
                    <p align="center">Al importar mantener los titulos y las columnas
                      en sus respectivas ubicaciones</p>
                    <p>Para importar las clasificaciones CIE, debe introducir la clasificacion
                      con su respectiva sub clasificacion,
                      en caso de que una clasificacion tuviera varias sub clasificacion es necesario
                      que la clasificacion se repita.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-for="(error, index) in errors" :key="index">
              <div class="col-sm-12">
                <div class="alert alert-warning">
                  <button type="button" class="close" data-dismiss="alert"
                  data-auto-dismiss="2000" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  <strong>..Verifique en : {{error}}</strong>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="clasificaciones.length > 0">
              <div class="col-sm-12">
                <b-table class="table table-striped table-hover"
                :fields="fieldsClasificacions"
                :items="tableClasificaciones"
                :per-page="perPageClasificaciones">
                <template #cell(eliminar)="items">
                  <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                    @click="eliminarItem(items.index)"
                    style="cursor: pointer"></i>
                </template>
                </b-table>
              </div>
              <div class="col-sm-4 offset-sm-8">
                <b-pagination
                  v-model="currentPageClasificaciones"
                  :total-rows="clasificaciones.length"
                  :per-page="perPageClasificaciones"
                  @click.native="listarTablaClasificacion"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheImportClasificacion',
  data() {
    return {
      errors: [],
      clasificaciones: [],
      file: null,
      existeArchivo: false,
      fieldsClasificacions: [
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'producto_salud', label: 'Producto Salud', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '20%' }, sortable: true,
        },
        {
          key: 'grupo', label: 'Grupo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'nombre_diagnostico', label: 'Nombre Diagnostico', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '20%' }, sortable: true,
        },
        {
          key: 'codigo_cie', label: 'Codigo Cie', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
      ],
      perPageClasificaciones: 100,
      currentPageClasificaciones: 1,
      tableClasificaciones: [],
      isUploading: false,
    };
  },
  methods: {
    handleFileUpload() {
      this.existeArchivo = true;
      const [files] = this.$refs.file.files;
      this.file = files;
    },
    downloadPlantillaNewDatos() {
      axios.get('/clinic/parametros/clasificacion_cie/load_massive/download', { responseType: 'blob' })
        .then((response) => {
          const BLOB = new Blob([response.data], { type: 'data:application/vnd.ms-excel' });
          const DOWNLOAD_URL = URL.createObjectURL(BLOB);
          const a = document.createElement('a');
          a.href = DOWNLOAD_URL;
          a.download = 'Formato para importar nuevas clasificacion.xlsx';
          document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          util.showNotify(error.response.data.message, 'error');
        });
    },
    subir() {
      this.isUploading = true;
      this.currentPageClasificaciones = 1;
      this.errors = [];
      this.clasificaciones = [];
      const DATA = new FormData();
      DATA.append('file', this.file);
      axios.post('/clinic/parametros/clasificacion_cie/load_massive/upload_import', DATA)
        .then((response) => {
          this.clasificaciones = response.data.data.clasificacions;
          const FIN = this.currentPageClasificaciones * this.perPageClasificaciones;
          this.tableClasificaciones = this.clasificaciones.slice(0, FIN);
          util.showNotify(response.data.message, 'success');
        })
        .catch((error) => {
          this.tableClasificaciones = [];
          this.clasificaciones = [];
          if (error.message === 'Network Error') {
            util.showNotify('Error al subir un archivo modificado, refresque la pagina e intente nuevamente.', 'error');
            return;
          }
          if (error.response.data.error === 2001) {
            util.showNotify(error.response.data.message, 'error');
          } else {
            this.errors = error.response.data ? error.response.data.data : { message: 'Verifique su Archivo', error: 'Error al Subir' };
            const UNICOS = new Set(this.errors);
            this.errors = UNICOS;
          }
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    listarTablaClasificacion() {
      const INICIO = (this.currentPageClasificaciones - 1) * this.perPageClasificaciones;
      const FIN = this.currentPageClasificaciones * this.perPageClasificaciones;
      this.tableClasificaciones = this.clasificaciones.slice(INICIO, FIN);
    },
    eliminarItem(index) {
      const REFERENCIA = (this.currentPageClasificaciones - 1) * this.perPageClasificaciones;
      this.tableClasificaciones.splice(index, 1);
      this.clasificaciones.splice(index + REFERENCIA, 1);
    },
    async guardar() {
      this.isUploading = true;
      try {
        const REQUEST = {
          clasificacions: this.clasificaciones,
        };
        const RESPONSE = await axios.post('/clinic/parametros/clasificacion_cie/load_massive/store_import', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isUploading = false;
        this.componentShow = 'TheIndexClasificacionCie';
      }
    },
  },
  computed: {
    ...mapFields('formularioClasificacion', [
      'componentShow',
    ]),
  },
};
</script>
