import { render, staticRenderFns } from "./TheCama.vue?vue&type=template&id=62bf7f74&scoped=true&"
import script from "./TheCama.vue?vue&type=script&lang=js&"
export * from "./TheCama.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62bf7f74",
  null
  
)

export default component.exports