<template>
  <div class="col-sm-12 ml-3">
    <div class="row mb-3">
      <div class="col-sm-6 border border-light py-2">
        <div class="row">
          <div class="col-sm-3 pr-0 text-right">
            <label class="col-form-label font-weight-bold">
              {{labelForm}}:
            </label>
          </div>
          <div class="col-sm-3">
            <input type="text"
              v-model="inputClase"
              @keyup.enter="agregarModificarItem"
              class="form-control"
              placeholder="Clase"
              autocomplete="off"
            >
          </div>
          <div class="col-sm-3 offset-sm-3">
            <button
              class="btn btn-success"
              @click="agregarModificarItem"
              :disabled="inputDisabled"
            >
              {{textButtonForm}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 px-0">
        <b-table
          class="table table-striped table-hover"
          :busy="isLoading"
          :fields="fields"
          :items="listaClases"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(editar)="items">
            <i
              @click="editarItem(items.item)"
              class="nav-icon i-Pen-5 text-success font-weight-bold"
              style="cursor: pointer"
            ></i>
          </template>
          <template #cell(eliminar)="items">
            <i
              @click="onClickEliminarIcon(items.item)"
              v-b-modal.modal-eliminar
              class="nav-icon i-Close-Window text-danger font-weight-bold"
              style="cursor: pointer"></i>
          </template>
        </b-table>
        <b-modal id="modal-eliminar"
          :title="'Seguro que quieres eliminar la Clase - N° '+selectedNro+'?'"
          hide-footer
        >
          <div class="row">
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                Eliminar
              </button>
            </div>
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-outline-dark"
              @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
            </div>
          </div>
        </b-modal>
        <b-modal id="modal-info-eliminar"
          :title="'ACCION NO PERMITIDA'"
          size="lg"
          hide-footer
        >
          <template #modal-title>
            <span class="text-danger">ACCION NO PERMITIDA!</span>
          </template>
          <div class="row">
            <div class="col-sm-11 pl-3">
              <p>Existen instancias relacionadas. A continuación se detallan:</p>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-6 pr-1">
                  <b-table class="table table-striped table-hover"
                    :fields="fieldsInfo"
                    :items="listaPisoSalas"
                  >
                    <template #thead-top>
                      <b-tr>
                        <b-th colspan="2" class="text-center py-0">Piso - Sala</b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </div>
                <div class="col-sm-6 pl-1">
                  <b-table class="table table-striped table-hover"
                    :fields="fieldsInfo"
                    :items="listaSalaCamas"
                  >
                    <template #thead-top>
                      <b-tr>
                        <b-th colspan="2" class="text-center py-0">Sala - Cama</b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {

  name: 'Clase',
  data() {
    return {
      isLoading: true,
      fields: [
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'numero', label: 'ID', thStyle: { width: '12%' },
        },
        {
          key: 'nombre', label: 'Clase', thStyle: { width: '72%' },
        },
      ],
      listaClases: [],
      inputClase: '',
      inputDisabled: false,
      selectedId: null,
      selectedNro: null,
      labelForm: 'Agregar Clase',
      textButtonForm: 'Agregar',
      modoEdicion: false,
      fieldsInfo: [
        {
          key: 'id', label: 'ID', thStyle: { width: '15%' },
        },
        {
          key: 'nombre', label: 'Nombre', thStyle: { width: '85%' },
        },
      ],
      listaPisoSalas: [],
      listaSalaCamas: [],
    };
  },
  created() {
    this.listarClases();
  },
  methods: {
    async listarClases() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/clase');
        this.listaClases = RESPONSE.data.data;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async guardarDatos() {
      const REQUEST = {
        nombre: this.inputClase,
      };
      let estado = false;
      try {
        const RESPONSE = await axios.post('/clinic/parametros/clase', REQUEST);
        await this.listarClases();
        util.showNotify(RESPONSE.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    agregarModificarItem() {
      if (this.inputClase === '') {
        util.showNotify('Escriba una Clase.', 'warn');
        return;
      }
      this.inputDisabled = true;
      if (this.modoEdicion) {
        if (this.actualizarItem()) {
          this.inputClase = '';
          this.selectedId = null;
          this.desactivarModoEdicion();
        }
      } else {
        this.selectedId = null;
        this.selectedNro = null;
        if (this.guardarDatos()) {
          this.inputClase = '';
        }
      }
      this.inputDisabled = false;
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    onClickEliminarIcon(item) {
      if (this.modoEdicion) {
        this.inputClase = '';
        this.desactivarModoEdicion();
      }
      this.setItems(item);
    },
    eliminarRegistroLocalmente() {
      this.listaClases = this.listaClases.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.listaPisoSalas = [];
      this.listaSalaCamas = [];
      this.$bvModal.hide('modal-eliminar');
      try {
        const OLD_LIST = this.listaClases;
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/parametros/clase/${this.selectedId}`);
        if (Array.isArray(RESPONSE.data.data)) {
          util.showNotify(RESPONSE.data.message, 'success');
        } else {
          this.listaClases = OLD_LIST;
          this.listaPisoSalas = RESPONSE.data.data.piso_salas;
          this.listaSalaCamas = RESPONSE.data.data.sala_camas;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarClases();
      }
      if (this.listaPisoSalas.length > 0 || this.listaSalaCamas.length > 0) {
        this.$bvModal.show('modal-info-eliminar');
      }
    },
    activarModoEdicion() {
      this.modoEdicion = true;
      this.labelForm = 'Modificar Clase';
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicion = false;
      this.labelForm = 'Agregar Clase';
      this.textButtonForm = 'Agregar';
    },
    editarItem(item) {
      this.activarModoEdicion();
      this.inputClase = item.nombre;
      this.selectedId = item.id;
      this.selectedNro = null;
    },
    async actualizarItem() {
      const REQUEST = {
        nombre: this.inputClase,
      };
      let estado = false;
      try {
        const response = await axios.put(`/clinic/parametros/clase/${this.selectedId}`, REQUEST);
        await this.listarClases();
        util.showNotify(response.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
  },
};
</script>

<style scoped>
</style>
