<template>
  <div>
    <div class="breadcrumb">
        <h1>Nuevo Categoria</h1>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <form autocomplete="off">
            <div class="card-header">
              <div class="row">
                  <div class="col-sm-12">
                      <button class="btn th-custom-color text-dark"
                      type="button"
                      :disabled="buttonDisabled"
                      @click="guardarCategoria">Guardar</button>
                      <button type="button" class="btn btn-secondary m-1"
                        @click="componentShow = 'TheIndexCategoriaLinae'">
                          Cancelar
                      </button>
                  </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <label for="codigo_categoria"
                class="col-sm-2 col-form-label text-right">
                Codigo Categoria:
                <span
                    class="text-danger">*
                </span>
                </label>
                <div class="col-sm-2">
                    <input type="text" class="form-control"
                    v-model="codigoCategoria"
                    placeholder="Ingrese el codigo" required>
                </div>
                <label for="nombre_categoria"
                class="col-sm-2 col-form-label text-right">
                  Nombre Categoria:
                  <span
                    class="text-danger">*
                  </span>
                </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control"
                  v-model="nombreCategoria"
                  placeholder="Ingrese el nombre de la categoria" required>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-12">
                  <h4>SubCategoria</h4>
                </div>
              </div>
              <div class="form-group row">
                <label for="codigo_sub_categoria"
                class="col-sm-2 col-form-label text-right">
                Codigo Sub Categoria:
                <span
                    class="text-danger">*
                </span>
                </label>
                <div class="col-sm-2">
                    <input type="text" class="form-control"
                    v-model="codigoSubCategoria"
                    placeholder="Ingrese el codigo" required>
                </div>
                <label for="nombre_sub_categoria"
                class="col-sm-2 col-form-label text-right">
                  Nombre Sub Categoria:
                  <span
                    class="text-danger">*
                  </span>
                </label>
                <div class="col-sm-3">
                  <input type="text" class="form-control"
                  v-model="nombreSubCategoria"
                  placeholder="Ingrese el nombre de la sub categoria" required>
                </div>
                <div class="col-sm-3">
                  <button class="btn th-custom-color text-dark"
                  type="button"
                  @click="addSubCategoria()">
                  Agregar
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div style="display: flex; width: 100%; text-align:center">
                    <table class="table table-bordered table-hover" width="100%">
                      <thead>
                        <tr>
                          <th width="3%"></th>
                          <th width="3%"></th>
                          <th width="10%" class="text-center">ID</th>
                          <th width="20%" class="text-center">Codigo</th>
                          <th width="45%">Nombre</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(subCategoria, index) in listaSubCategorias" :key="index">
                          <td class="text-center">
                            <i class="nav-icon i-Pen-5 font-weight-bold text-success"
                            style="cursor: pointer"
                            @click="edit(subCategoria)"
                            ></i>
                          </td>
                          <td class="text-center">
                            <a class="delete text-danger">
                                <i class="nav-icon i-Close-Window font-weight-bold"
                                style="cursor: pointer"
                                @click="eliminar(index)"
                                ></i>
                            </a>
                          </td>
                          <td>
                            {{ index + 1 }}
                          </td>
                          <td>
                            <input type="text"
                            v-model="subCategoria.codigo"
                            :readonly="readInputs[subCategoria.numero - 1].value"
                            class="form-control">
                          </td>
                          <td>
                            <input type="text"
                            v-model="subCategoria.nombre"
                            :readonly="readInputs[subCategoria.numero - 1].value"
                            class="form-control">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { util } from '@/plugins/util';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheNuevoCategoriaLinae',
  data() {
    return {
      buttonDisabled: false,
    };
  },
  methods: {
    addSubCategoria() {
      if (this.validarAddCategoria()) {
        this.listaSubCategorias.push({
          numero: this.readInputs.length + 1,
          nombre: this.nombreSubCategoria,
          codigo: this.codigoSubCategoria,
        });
        this.readInputs.push({ value: true });
        this.limpiarDataSubCategoria();
      }
    },
    limpiarDataSubCategoria() {
      this.codigoSubCategoria = null;
      this.nombreSubCategoria = null;
    },
    validarAddCategoria() {
      let valido = true;
      if (!this.nombreSubCategoria) {
        valido = false;
        util.showNotify('El nombre de la sub categoria es requerido', 'warn');
      }
      if (!this.codigoSubCategoria) {
        valido = false;
        util.showNotify('El codigo de la sub categoria es requerido', 'warn');
      }
      const codigo = this.listaSubCategorias.find((el) => (
        el.codigo === this.codigoSubCategoria));
      if (codigo) {
        valido = false;
        util.showNotify('El codigo de la sub categoria ya existe en el detalle', 'warn');
      }
      return valido;
    },
    async guardarCategoria() {
      this.buttonDisabled = true;
      if (this.validar()) {
        const STORE_OK = await this.callStoreApi();
        if (STORE_OK) {
          this.callIndexApi();
        }
      }
      this.buttonDisabled = false;
    },
    validar() {
      let valido = true;
      if (!this.codigoCategoria) {
        valido = false;
        util.showNotify('El codigo de la categoria es requerido', 'warn');
      }
      if (!this.nombreCategoria) {
        valido = false;
        util.showNotify('El nombre de la categoria es requerido', 'warn');
      }
      if (this.listaSubCategorias.length === 0) {
        valido = false;
        util.showNotify('Debe agregar al menos una sub categoria', 'warn');
      }
      return valido;
    },
    eliminar(index) {
      this.listaSubCategorias.splice(index, 1);
    },
    edit(row) {
      this.readInputs[row.numero - 1].value = false;
    },
    ...mapActions('categoriaLinaeApis', [
      'callIndexApi',
      'callStoreApi',
    ]),
    ...mapActions('formularioCategoriaLinae', [
      'resetFoumularioCategoriStore',
    ]),
  },
  beforeDestroy() {
    this.resetFoumularioCategoriStore();
  },
  computed: {
    ...mapFields('formularioCategoriaLinae', [
      'componentShow',
      'nombreCategoria',
      'codigoCategoria',
      'listaSubCategorias',
      'codigoSubCategoria',
      'nombreSubCategoria',
      'readInputs',
    ]),
  },
};
</script>
