<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3">
                <button class="btn th-custom-color px-4 rounded mr-2"
                  @click="componentShow='TheNuevoCategoriaLinae'">
                  Nuevo
                </button>
                <button class="btn th-custom-color px-4 rounded mr-2"
                @click="componentShow='TheImportCategoriaLinae'">
                  Importar
                </button>
              </div>
              <div class="col-sm-3 offset-sm-6">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input id="filterbyState"
                      :checked="estado"
                      v-model="estado"
                      @change="listarRegistros"
                      type="checkbox">
                      <span class="slider"></span>
                      <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="porPagina"
                    :options="paginaOpciones"
                    @change="listarRegistros">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" v-model="busqueda"
                    autocomplete="off" placeholder="Buscar" @keyup="filtrarBusqueda">
                  </div>
                </div>
              </div>
              <!-- tabla -->
              <b-table class="table table-striped table-hover"
              :fields="fields" :items="listarTabla"
              :busy="isLoading"
              :per-page="porPagina"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template v-if="estado" #cell(editar)="data">
                  <i
                    class="nav-icon i-Pen-5 text-success font-weight-bold"
                    @click="editClasificacion(data.item)"
                    style="cursor: pointer"
                  ></i>
              </template>
              <template v-if="estado" #cell(eliminar)="data">
                <i
                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                  @click="onClickEliminarIcon(data.item)"
                  style="cursor: pointer"
                ></i>
              </template>
            </b-table>
            <!-- Paginacion -->
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="paginaActual"
                  :total-rows="totalFilas"
                  :per-page="porPagina"
                  @input="listarRegistros"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <!-- Fin Paginacion -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-eliminar"
      :title="'Seguro que quieres eliminar la clasificacion - N° '+selectedNro+'?'"
      hide-footer>
      <div class="row">
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
            Eliminar
          </button>
        </div>
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-outline-dark"
          @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheCategoriaIndex',
  data() {
    return {
      delayTimeout: null,
      selectedNro: null,
      selectId: null,
    };
  },
  created() {
    this.listarRegistros();
  },
  methods: {
    async listarRegistros() {
      await this.callIndexApi();
      this.isLoading = false;
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarRegistros, DELAY);
    },
    onClickEliminarIcon(item) {
      this.selectedNro = item.numero;
      this.selectId = item.id;
      this.$bvModal.show('modal-eliminar');
    },
    async eliminarRegistro() {
      const DELETE_OK = await this.callDestroyApi(this.selectId);
      if (DELETE_OK) {
        this.$bvModal.hide('modal-eliminar');
        this.selectedNro = null;
        this.selectId = null;
      }
    },
    async editClasificacion(item) {
      const EDIT_OK = await this.callEditApi(item.id);
      if (EDIT_OK) {
        this.componentShow = 'TheEditCategoriaLinae';
      }
    },
    ...mapActions('categoriaLinaeApis', [
      'callIndexApi',
      'callDestroyApi',
      'callEditApi',
    ]),
  },
  computed: {
    ...mapFields('formularioCategoriaLinae', [
      'busqueda',
      'listarTabla',
      'estado',
      'isLoading',
      'fields',
      'sortDesc',
      'sortBy',
      'paginaOpciones',
      'paginaActual',
      'porPagina',
      'totalFilas',
      'componentShow',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
};
</script>
