<template>
  <div class="col-sm-12 ml-3">
    <div class="row mb-3">
      <div class="col-sm-6 border border-light py-2">
        <div class="row">
          <div class="col-sm-4 pr-1">
            <multiselect
              v-model="pisoSelected"
              :options="listaPisos"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Seleccione Piso"
              :searchable="true">
              <span slot="caret" v-if="!(pisoSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-5 px-1">
            <multiselect
              v-model="consultorioSelected"
              :options="listaConsultoriosFiltered"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Seleccione Consultorio"
              :searchable="true">
              <span slot="caret" v-if="!(consultorioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-3 pl-1">
            <button
              class="btn btn-success col-sm-10"
              @click="agregarModificarItem"
              :disabled="inputDisabled"
            >
              {{textButtonForm}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 px-0">
        <b-table
          class="table table-striped table-hover"
          :busy="isLoading"
          :fields="fields"
          :items="listaPisoConsultorio"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(editar)="items">
            <i
              @click="editarItem(items.item)"
              class="nav-icon i-Pen-5 text-success font-weight-bold"
              style="cursor: pointer"
            ></i>
          </template>
          <template #cell(eliminar)="items">
            <i
              @click="onClickEliminarIcon(items.item)"
              v-b-modal.modal-eliminar
              class="nav-icon i-Close-Window text-danger font-weight-bold"
              style="cursor: pointer"
            ></i>
          </template>
        </b-table>
        <b-modal id="modal-eliminar"
          :title="'Seguro que quieres eliminar el Registro - N° '+selectedNro+'?'"
          hide-footer
        >
          <div class="row">
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                Eliminar
              </button>
            </div>
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-outline-dark"
              @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'PisoConsultorio',
  components: {
    Multiselect,
  },
  watch: {
    listaPisoConsultorio() {
      this.updateComboConsultorio();
    },
  },
  data() {
    return {
      isLoading: true,
      pisoSelected: null,
      listaPisos: [],
      consultorioSelected: null,
      listaConsultorios: [],
      listaConsultoriosFiltered: [],
      inputDisabled: false,
      textButtonForm: 'Agregar',
      modoEdicion: false,
      fields: [
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'numero', label: 'ID', thStyle: { width: '12%' },
        },
        {
          key: 'piso_nombre', label: 'Piso', thStyle: { width: '36%' },
        },
        {
          key: 'consultorio_nombre', label: 'Consultorio', thStyle: { width: '36%' },
        },
      ],
      listaPisoConsultorio: [],
      selectedId: null,
      selectedNro: null,
    };
  },
  async created() {
    await this.cargarDatosIniciales();
    this.listarPisoConsultorio();
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/piso_consultorio/create');
        this.listaPisos = RESPONSE.data.data.pisos;
        this.listaConsultorios = RESPONSE.data.data.consultorios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async listarPisoConsultorio() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/piso_consultorio');
        this.listaPisoConsultorio = RESPONSE.data.data;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async guardarDatos() {
      const REQUEST = {
        piso_id: this.pisoSelected.id,
        consultorio_id: this.consultorioSelected.id,
      };
      let estado = false;
      try {
        const RESPONSE = await axios.post('/clinic/parametros/piso_consultorio', REQUEST);
        await this.listarPisoConsultorio();
        util.showNotify(RESPONSE.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    async actualizarItem() {
      const REQUEST = {
        piso_id: this.pisoSelected.id,
        consultorio_id: this.consultorioSelected.id,
      };
      let estado = false;
      try {
        const URL = `/clinic/parametros/piso_consultorio/${this.selectedId}`;
        const response = await axios.put(URL, REQUEST);
        await this.listarPisoConsultorio();
        util.showNotify(response.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    activarModoEdicion() {
      this.modoEdicion = true;
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicion = false;
      this.textButtonForm = 'Agregar';
    },
    agregarModificarItem() {
      if (this.pisoSelected === null) {
        util.showNotify('Elije un Piso.', 'warn');
        return;
      }
      if (this.consultorioSelected === null) {
        util.showNotify('Elije un Consultorio.', 'warn');
        return;
      }
      this.inputDisabled = true;
      if (this.modoEdicion) {
        if (this.actualizarItem()) {
          this.pisoSelected = null;
          this.consultorioSelected = null;
          this.selectedId = null;
          this.desactivarModoEdicion();
        }
      } else {
        this.selectedId = null;
        this.selectedNro = null;
        if (this.guardarDatos()) {
          this.pisoSelected = null;
          this.consultorioSelected = null;
        }
      }
      this.inputDisabled = false;
    },
    cargarComboPisoById(id) {
      if (id === null) {
        return;
      }
      this.pisoSelected = this.listaPisos.find((el) => el.id === id);
    },
    cargarComboConsultorioById(id) {
      if (id === null) {
        return;
      }
      this.consultorioSelected = this.listaConsultorios.find((el) => el.id === id);
    },
    async getDataToEdit() {
      try {
        const URL = `/clinic/parametros/piso_consultorio/${this.selectedId}/edit`;
        const RESPONSE = await axios.get(URL);
        const PISO_ID = RESPONSE.data.data.piso_id;
        const CONSULTORIO_ID = RESPONSE.data.data.consultorio_id;
        this.cargarComboPisoById(PISO_ID);
        this.cargarComboConsultorioById(CONSULTORIO_ID);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    updateComboConsultorioFromEditar() {
      if (this.modoEdicion === true) {
        let listaTemporal = [...this.listaConsultorios];
        this.listaPisoConsultorio.forEach((item) => {
          if (item.id === this.selectedId) {
            listaTemporal = listaTemporal.filter((el) => (
              el.id !== item.consultorio_id || this.selectedId === item.id
            ));
          } else {
            listaTemporal = listaTemporal.filter((el) => el.id !== item.consultorio_id);
          }
        });
        this.listaConsultoriosFiltered = listaTemporal;
      }
    },
    updateComboConsultorio() {
      let listaTemporal = [...this.listaConsultorios];
      this.listaPisoConsultorio.forEach((item) => {
        listaTemporal = listaTemporal.filter((el) => el.id !== item.consultorio_id);
      });
      this.listaConsultoriosFiltered = listaTemporal;
    },
    editarItem(item) {
      this.activarModoEdicion();
      this.selectedId = item.id;
      this.selectedNro = null;
      this.getDataToEdit();
      this.updateComboConsultorioFromEditar();
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    onClickEliminarIcon(item) {
      if (this.modoEdicion) {
        this.pisoSelected = null;
        this.consultorioSelected = null;
        this.updateComboConsultorio();
        this.desactivarModoEdicion();
      }
      this.setItems(item);
    },
    eliminarRegistroLocalmente() {
      this.listaPisoConsultorio = this.listaPisoConsultorio.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const URL = `/clinic/parametros/piso_consultorio/${this.selectedId}`;
        const RESPONSE = await axios.delete(URL);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarPisoConsultorio();
      }
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
