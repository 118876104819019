<template>
  <b-modal
    id="modal-categoria"
    title="Categoria Arancel"
    hide-footer
  >
    <div class="row mb-3">
      <div class="col-sm-8 pl-0 offset-sm-1">
        <input
          id="inputCategoria"
          v-model="nombreCategoria"
          type="text"
          class="form-control"
          :maxlength="maxLengthInput"
          placeholder="Ingrese una Categoria"
          :disabled="inputDisabled"
          @keyup.enter="guardarCategoria"
          autocomplete="off"
        >
      </div>
      <div class="col-sm-3">
        <button
          class="btn btn-warning"
          @click="guardarCategoria"
        >
          Agregar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <b-table
          responsive
          class="table table-striped table-bordered border-0 table-hover"
          :fields="fieldsTabla"
          :items="listaCategorias"
        >
          <template #cell(eliminar)="row">
            <div class="text-center">
              <i
                class="nav-icon i-Close-Window text-danger font-weight-bold"
                style="cursor: pointer"
                @click="eliminarCategoria(row.item)"
              ></i>
            </div>
          </template>
          <template #cell(nombre)="row">
            <span
              v-show="!row.item.editable"
              style="cursor: pointer"
              @click="row.item.editable = true"
              v-b-popover.hover.top="'haz click para editar'"
            >
              {{row.item.nombre}}
            </span>
            <b-input-group
              v-show="row.item.editable"
            >
              <input
                v-model="row.item.nombre"
                type="text"
                class="form-control"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="actualizarCategoria(row.item);row.item.editable = false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheModalCategoria',
  data() {
    return {
      nombreCategoria: '',
      inputDisabled: false,
      fieldsTabla: [
        {
          key: 'eliminar', label: '', thClass: 'border-bottom', thStyle: { width: '8%' },
        },
        {
          key: 'numero', label: 'Id', thClass: 'border-bottom', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'border-bottom', thStyle: { width: '82%' },
        },
      ],
      listaCategorias: [],
      maxLengthInput: 100,
    };
  },
  created() {
    this.listarCategorias();
  },
  methods: {
    async listarCategorias() {
      try {
        const RESPONSE = await axios.get('/clinic/parametros/categoria_arancel');
        const LISTA_TEMPORAL = RESPONSE.data.data.categoria_arancel;
        LISTA_TEMPORAL.forEach((item, index) => { LISTA_TEMPORAL[index].editable = false; });
        this.listaCategorias = LISTA_TEMPORAL;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async actualizarCategoria(item) {
      const REQUEST = { nombre: item.nombre };
      try {
        const RESPONSE = await axios.put(`/clinic/parametros/categoria_arancel/${item.id}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async callToStoreAPI() {
      const REQUEST = { nombre: this.nombreCategoria };
      let estado = false;
      try {
        const response = await axios.post('/clinic/parametros/categoria_arancel/store', REQUEST);
        await this.listarCategorias();
        estado = true;
        util.showNotify(response.data.message, 'success');
      } catch (error) {
        const mensajes = error.response.data.data.nombre;
        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
      }
      return estado;
    },
    guardarCategoria() {
      this.inputDisabled = true;
      if (this.callToStoreAPI()) {
        this.nombreCategoria = '';
      }
      this.inputDisabled = false;
    },
    async eliminarCategoria(fila) {
      try {
        this.listaCategorias = this.listaCategorias.filter((item) => (
          item.id !== fila.id
        ));
        const RESPONSE = await axios.delete(`/clinic/parametros/categoria_arancel/${fila.id}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarCategorias();
      }
    },
  },
};
</script>
