import { render, staticRenderFns } from "./TheSalaCama.vue?vue&type=template&id=80f7326c&scoped=true&"
import script from "./TheSalaCama.vue?vue&type=script&lang=js&"
export * from "./TheSalaCama.vue?vue&type=script&lang=js&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80f7326c",
  null
  
)

export default component.exports