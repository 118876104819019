<template>
  <div>
    <TheIndexArancel
      v-if="isTheIndexArancel"
      @goToNuevo="componentShow = 'TheNuevoArancel'"
      @goToEdit="goToEditArancel($event)"
    />
    <TheNuevoArancel
      v-if="isTheNuevoArancel"
      @goToListar="componentShow = 'TheIndexArancel'"
    />
    <TheEditArancel
      v-if="isTheEditArancel"
      :arancel-id="arancelId"
      @goToListar="componentShow = 'TheIndexArancel'"
    />
  </div>
</template>

<script>
import TheIndexArancel from './TheIndexArancel.vue';
import TheNuevoArancel from './TheNuevoArancel.vue';
import TheEditArancel from './TheEditArancel.vue';

export default {
  name: 'TheTabArancel',
  components: {
    TheIndexArancel,
    TheNuevoArancel,
    TheEditArancel,
  },
  data() {
    return {
      componentShow: 'TheIndexArancel',
      arancelId: null,
    };
  },
  methods: {
    goToEditArancel(id) {
      this.arancelId = id;
      this.componentShow = 'TheEditArancel';
    },
  },
  computed: {
    isTheIndexArancel() {
      return this.componentShow === 'TheIndexArancel';
    },
    isTheNuevoArancel() {
      return this.componentShow === 'TheNuevoArancel';
    },
    isTheEditArancel() {
      return this.componentShow === 'TheEditArancel';
    },
  },
};
</script>
