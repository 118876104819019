import { render, staticRenderFns } from "./TheModalCategoriaMedico.vue?vue&type=template&id=17abbcc3&scoped=true&"
import script from "./TheModalCategoriaMedico.vue?vue&type=script&lang=js&"
export * from "./TheModalCategoriaMedico.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17abbcc3",
  null
  
)

export default component.exports