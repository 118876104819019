<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-9">
        <div class="row">
          <button
            class="btn border border-light col-sm-2 mx-3"
            :class="isPisoSala ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'PisoSala'"
          >
            <b>Piso - Sala</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isSalaCama ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'SalaCama'"
          >
            <b>Sala - Cama</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isPisoConsultorio ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'PisoConsultorio'"
          >
            <b>Piso - Consultorio</b>
          </button>
        </div>
      </div>
      <div class="col-sm-3">
        <b-form-group v-if="isSalaCama" style="margin: 0px; transform: translate(32%, 0%);">
          <b-form-checkbox v-model="isMultiselectDisabled" @change="changeTipoTratamiento">
            (Activado Tipo)
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <PisoSala v-if="isPisoSala"/>
      <SalaCama v-if="isSalaCama" :isMultiselectDisabled="isMultiselectDisabled"/>
      <PisoConsultorio v-if="isPisoConsultorio"/>
    </div>
  </div>
</template>

<script>

import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import PisoSala from './ThePisoSala.vue';
import SalaCama from './TheSalaCama.vue';
import PisoConsultorio from './ThePisoConsultorio.vue';

export default {
  name: 'Asignar',
  components: {
    PisoSala,
    SalaCama,
    PisoConsultorio,
  },
  data() {
    return {
      componentShow: 'PisoSala',
      isMultiselectDisabled: false,
    };
  },
  methods: {
    async cargarDatoTipoTratamiento() {
      try {
        const RESPONSE = await axios.get('/clinic/parametros/sala_camas/create');
        this.isMultiselectDisabled = RESPONSE.data.data.has_active_tipo_tratamiento;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async changeTipoTratamiento() {
      const REQUEST = {
        tipo_tratamiento: this.isMultiselectDisabled,
      };
      try {
        const RESPONSE = await axios.post('/clinic/servicio_complementario/store_parametro_servicio_internacion', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    isPisoSala() {
      return (this.componentShow === 'PisoSala');
    },
    isSalaCama() {
      return (this.componentShow === 'SalaCama');
    },
    isPisoConsultorio() {
      return (this.componentShow === 'PisoConsultorio');
    },
  },
  watch: {
    isSalaCama(newValue) {
      this.$emit('sala-cama-emitido', newValue);
    },
  },
  mounted() {
    this.cargarDatoTipoTratamiento();
  },
};
</script>

<style scoped>
</style>
