<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3 d-flex">
                <button
                  class="btn th-custom-color px-4 rounded mr-2"
                  @click="$emit('goToNuevo', true)"
                >
                  Nuevo
                </button>
                <b-input-group>
                  <input
                    type="text"
                    v-model="parametroArancel.nombre"
                    :placeholder="!parametroArancel.nombre ? 'S/N': ''"
                    class="form-control text-center"
                    @keyup.enter="updateTitle"
                  >
                  <b-input-group-append>
                    <button
                      class="btn btn-light px-2"
                      title="Actualizar Nombre"
                      @click="updateTitle"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        class="bi bi-floppy"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 2H9v3h2z"/>
                        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                          2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                          0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                          0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                          0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                          7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                          .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                          0 0 0-.5.5z"
                        />
                      </svg>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-sm-2">
                <button
                  @click="openModalCategoria"
                  class="btn btn-success w-75"
                >Categoria</button>
                <TheModalCategoria />
              </div>
              <div class="col-sm-6 offset-sm-1">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input
                      id="filterbyState"
                      :checked="estado"
                      v-model="estado"
                      @change="listarAranceles"
                      type="checkbox"
                    >
                      <span class="slider"></span>
                      <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="porPagina"
                    :options="paginaOpciones"
                    @change="listarAranceles"
                  >
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="busqueda"
                      @keyup="filtrarBusqueda"
                      autocomplete="off"
                      placeholder="Buscar"
                    >
                  </div>
                </div>
              </div>
              <b-table class="table table-striped table-hover"
                :fields="fields"
                :items="listaTabla"
                :busy="isLoading"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                    <strong>Cargando...</strong>
                  </div>
                </template>
                <template v-if="estado" #cell(editar)="data">
                  <i
                    @click="editArancel(data.item.id)"
                    class="nav-icon i-Pen-5 text-success font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </template>
                <template v-if="estado" #cell(eliminar)="items">
                  <i
                    @click="onClickEliminarIcon(items.item)"
                    class="nav-icon i-Close-Window font-weight-bold text-danger"
                    style="cursor: pointer"
                  ></i>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="paginaActual"
                  :total-rows="totalFilas"
                  :per-page="porPagina"
                  @input="listarAranceles"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-eliminar"
      :title="'Seguro que quieres eliminar el Arancel - N° '+selectedNro+'?'"
      hide-footer
    >
      <div class="row">
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
            Eliminar
          </button>
        </div>
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-outline-dark"
          @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import TheModalCategoria from './arancel/TheModalCategoria.vue';

export default {
  name: 'TheIndexArancel',
  components: {
    TheModalCategoria,
  },
  data() {
    return {
      estado: true,
      porPagina: 50,
      paginaActual: 1,
      paginaOpciones: [50, 100],
      totalFilas: 0,
      busqueda: '',
      isLoading: false,
      listaTabla: [],
      fields: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'categoria', label: 'Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '29%' }, sortable: true,
        },
        {
          key: 'paquete', label: 'Paquete', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
        },
      ],
      delayTimeout: null,
      selectedId: null,
      selectedNro: null,
      parametroArancel: {
        id: 0,
        nombre: '',
      },
    };
  },
  created() {
    this.listarAranceles();
  },
  methods: {
    async listarAranceles() {
      this.isLoading = true;
      try {
        const request = {
          state: this.estado,
          filter_key: this.busqueda,
          page: this.paginaActual,
          per_page: this.porPagina,
        };
        const response = await axios.get('/clinic/parametros/arancel', {
          params: request,
        });
        this.totalFilas = response.data.data.aranceles.total;
        this.listaTabla = response.data.data.aranceles.data;
        const parametroTitulo = response.data.data.nombre_parametro_arancel;
        if (parametroTitulo !== null) {
          this.parametroArancel = parametroTitulo;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarAranceles, DELAY);
    },
    openModalCategoria() {
      this.$bvModal.show('modal-categoria');
    },
    editArancel(id) {
      this.$emit('goToEdit', id);
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    onClickEliminarIcon(item) {
      this.setItems(item);
      this.$bvModal.show('modal-eliminar');
    },
    eliminarRegistroLocalmente() {
      this.listaTabla = this.listaTabla.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/parametros/arancel/${this.selectedId}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarAranceles();
      }
    },
    async updateTitle() {
      if (this.parametroArancel.nombre === '') {
        util.showNotify('El nombre es requerido', 'warn');
        return;
      }

      try {
        const REQUEST = {
          id: this.parametroArancel.id,
          nombre: this.parametroArancel.nombre,
        };
        const RESPONSE = await axios.post('/clinic/parametros/nombre_parametro_arancel', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.parametroArancel = RESPONSE.data.data.nombre;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
};
</script>
